// src/pages/ChatPage.jsx
import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/chat/Sidebar';
import ChatWindow from '../../components/chat/ChatWindow';
import { useLocation } from 'react-router-dom';
import { get, post } from '../../helpers/apiHelper';
import useAuthStore from '../../store/authStore';
import useNotificationStore from '../../store/notificationStore';
import io from 'socket.io-client';

const socketUrl = process.env.REACT_APP_SOCKET_URL;

function ChatPage() {
    const location = useLocation();
    const { chatId } = location.state || {};
    const { user } = useAuthStore();

    const [selectedChat, setSelectedChat] = useState(null);
    const [chats, setChats] = useState([]);
    const [socket, setSocket] = useState(null);

    const setTotalUnreadMessages = useNotificationStore((state) => state.setTotalUnreadMessages);

    useEffect(() => {
        if (user) {
            // Inicializar el socket aquí
            const newSocket = io(socketUrl, {
                auth: {
                    token: localStorage.getItem('auth-storage-repaso-pro')
                        ? JSON.parse(localStorage.getItem('auth-storage-repaso-pro')).state.token
                        : '',
                },
            });

            setSocket(newSocket);

            return () => {
                newSocket.disconnect();
            };
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            get(`chats/user/${user.userId}`)
                .then((data) => {
                    setChats(data);

                    // Calcular el total de mensajes sin leer
                    const totalUnread = data.reduce((sum, chat) => sum + chat.unreadMessagesCount, 0);
                    setTotalUnreadMessages(totalUnread);

                    if (chatId) {
                        const chat = data.find((c) => c.id === chatId);
                        if (chat) {
                            setSelectedChat(chat);
                        }
                    }
                })
                .catch((error) => console.error('Error fetching chats:', error));
        }
    }, [user, chatId]);

    useEffect(() => {
        if (socket) {
            socket.on('newMessage', (message) => {
                const chatId = message.chat.id;

                // Actualizar los chats
                setChats((prevChats) => {
                    const updatedChats = prevChats.map((chat) => {
                        if (chat.id === chatId) {
                            // Incrementar el número de mensajes sin leer si el chat no está seleccionado
                            const isChatSelected = selectedChat && selectedChat.id === chatId;
                            const unreadCount = isChatSelected ? 0 : (chat.unreadMessagesCount || 0) + 1;

                            return {
                                ...chat,
                                messages: [...(chat.messages || []), message],
                                unreadMessagesCount: unreadCount,
                            };
                        }
                        return chat;
                    });

                    // Actualizar el total de mensajes sin leer
                    const totalUnread = updatedChats.reduce((sum, chat) => sum + chat.unreadMessagesCount, 0);
                    setTotalUnreadMessages(totalUnread);

                    return updatedChats;
                });
            });

            return () => {
                socket.off('newMessage');
            };
        }
    }, [socket, selectedChat]);

    const handleSelectChat = (chat) => {
        setSelectedChat(chat);

        // Marcar mensajes como leídos
        post(`chats/${chat.id}/mark-as-read`, {})
            .then(() => {
                // Actualizar el estado de los chats y total de mensajes sin leer
                const updatedChats = chats.map((c) =>
                    c.id === chat.id ? { ...c, unreadMessagesCount: 0 } : c
                );
                setChats(updatedChats);

                const totalUnread = updatedChats.reduce((sum, chat) => sum + chat.unreadMessagesCount, 0);
                setTotalUnreadMessages(totalUnread);
            })
            .catch((error) => console.error('Error marking messages as read:', error));
    };

    return (
        <div className="flex h-[calc(100vh-160px)]">
            <Sidebar chats={chats} onSelectChat={handleSelectChat} />
            <ChatWindow chat={selectedChat} socket={socket} />
        </div>
    );
}

export default ChatPage;
