// src/components/Sidebar.js
import React from 'react';
import ChatItem from './ChatItem';

function Sidebar({ chats, onSelectChat }) {
    return (
        <div className="w-1/4 bg-gray-100 border-r border-gray-300 overflow-y-auto">
            <h2 className="text-xl font-bold p-4 border-b border-gray-300">Chats</h2>
            <ul>
                {chats.map((chat) => (
                    <ChatItem key={chat.id} chat={chat} onSelectChat={onSelectChat} />
                ))}
            </ul>
        </div>
    );
}

export default Sidebar;
