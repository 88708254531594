// src/components/admin/CategoryForm.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { get } from '../../helpers/apiHelper'; // Asegúrate de tener una función GET en apiHelper

Modal.setAppElement('#root');

function CategoryForm({ initialData = null, onSubmit, onCancel }) {
    const [name, setName] = useState(initialData ? initialData.name : '');
    const [category, setCategory] = useState(initialData ? initialData.category : '');
    const [subCategory, setSubCategory] = useState(initialData ? initialData.subCategory : '');
    const [speciality, setSpeciality] = useState(initialData ? initialData.speciality : '');

    const [subjects, setSubjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [specialities, setSpecialities] = useState([]);

    // Fetch all subjects on component mount
    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const data = await get('subjects'); // Endpoint: GET /subjects
                setSubjects(data);

                // Extraer categorías únicas
                const uniqueCategories = [...new Set(data.map(subject => subject.category))];
                setCategories(uniqueCategories);
            } catch (error) {
                console.error('Error fetching subjects:', error);
                Swal.fire('Error', 'No se pudieron cargar las materias.', 'error');
            }
        };

        fetchSubjects();
    }, []);

    // Actualizar subcategorías cuando cambia la categoría
    useEffect(() => {
        if (category) {
            const filteredSubCategories = subjects
                .filter(subject => subject.category === category)
                .map(subject => subject.subCategory);
            const uniqueSubCategories = [...new Set(filteredSubCategories)];
            setSubCategories(uniqueSubCategories);
            setSubCategory('');
            setSpeciality('');
            setSpecialities([]);
        } else {
            setSubCategories([]);
            setSubCategory('');
            setSpeciality('');
            setSpecialities([]);
        }
    }, [category, subjects]);

    // Actualizar especialidades cuando cambia la subcategoría
    useEffect(() => {
        if (subCategory) {
            const filteredSpecialities = subjects
                .filter(subject => subject.category === category && subject.subCategory === subCategory && subject.speciality)
                .map(subject => subject.speciality);
            const uniqueSpecialities = [...new Set(filteredSpecialities)];
            setSpecialities(uniqueSpecialities);
            setSpeciality('');
        } else {
            setSpecialities([]);
            setSpeciality('');
        }
    }, [subCategory, category, subjects]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validaciones del frontend antes de enviar al backend
        if (!name.trim() || !category.trim()) {
            Swal.fire('Error', 'Por favor, completa todos los campos requeridos.', 'error');
            return;
        }

        if (name.length > 50) {
            Swal.fire('Error', 'El nombre de la materia no debe exceder los 50 caracteres.', 'error');
            return;
        }

        const dataToSubmit = {
            name: name.trim(),
            category: category.trim(),
            subCategory: subCategory.trim() || undefined,
            speciality: speciality.trim() || undefined,
        };

        try {
            await onSubmit(dataToSubmit);
        } catch (error) {
            // El manejo de errores se realiza en el componente padre
            console.error('Error en handleSubmit:', error);
            Swal.fire('Error', 'No se pudo procesar la materia.', 'error');
        }
    };

    return (
        <Modal
            isOpen={true}
            onRequestClose={onCancel}
            contentLabel={initialData ? 'Editar Materia' : 'Agregar Materia'}
            className="modal bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto my-20"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        >
            <h2 className="text-2xl font-bold mb-4">
                {initialData ? 'Editar Materia' : 'Agregar Nueva Materia'}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">

                {/* Categoría */}
                <div>
                    <label className="block text-gray-700 mb-1">Categoría</label>
                    <select
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        required
                    >
                        <option value="" disabled>Seleccione la categoría</option>
                        {categories.map((cat) => (
                            <option key={cat} value={cat}>{cat}</option>
                        ))}
                    </select>
                </div>

                {/* Subcategoría (opcional) */}
                {subCategories.length > 0 && (
                    <div>
                        <label className="block text-gray-700 mb-1">Subcategoría</label>
                        <select
                            value={subCategory}
                            onChange={(e) => setSubCategory(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        >
                            <option value="" disabled>Seleccione la subcategoría</option>
                            {subCategories.map((subCat) => (
                                <option key={subCat} value={subCat}>{subCat}</option>
                            ))}
                        </select>
                    </div>
                )}

                {/* Especialidad (solo si hay especialidades disponibles) */}
                {specialities.length > 0 && (
                    <div>
                        <label className="block text-gray-700 mb-1">Especialidad</label>
                        <select
                            value={speciality}
                            onChange={(e) => setSpeciality(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                            required
                        >
                            <option value="" disabled>Seleccione la especialidad</option>
                            {specialities.map((spec) => (
                                <option key={spec} value={spec}>{spec}</option>
                            ))}
                        </select>
                    </div>
                )}

                {/* Nombre de la Materia */}
                <div>
                    <label className="block text-gray-700 mb-1">Nombre de la Materia</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Nombre de la Materia"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                        required
                        maxLength={50} // Asegura que no exceda los 50 caracteres
                    />
                </div>

                {/* Botones de Acción */}
                <div className="flex justify-end space-x-2">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                    >
                        Cancelar
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                    >
                        {initialData ? 'Actualizar' : 'Agregar'}
                    </button>
                </div>
            </form>
        </Modal>
    );

    CategoryForm.propTypes = {
        initialData: PropTypes.shape({
            name: PropTypes.string,
            category: PropTypes.string,
            subCategory: PropTypes.string,
            speciality: PropTypes.string,
        }),
        onSubmit: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

}
export default CategoryForm;