// src/components/auth/AvatarSelector.jsx
import React from 'react';
import AvatarModal from '../../components/profile/AvatarModal';

function AvatarSelector({ selectedAvatar, openAvatarModal }) {
    return (
        <div className="mt-4">
            {selectedAvatar && (
                <div className="flex justify-center mb-4">
                    <img src={selectedAvatar} alt="Selected Avatar" className="w-24 h-24 rounded-full" />
                </div>
            )}

            <button
                type="button"
                onClick={openAvatarModal}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
                Escoger Avatar
            </button>
        </div>
    );
}

export default AvatarSelector;
