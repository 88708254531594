// src/pages/TeachersList.jsx
import React, { useState, useEffect } from 'react';
import { get, post } from '../../helpers/apiHelper';
import TeacherCard from '../../components/TeacherCard';
import FilterPanel from '../../components/FilterPanel';
import useGeolocation from '../../hooks/useGeolocation';
import ReviewModal from '../../components/review/ReviewModal';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/authStore';

function TeachersList() {
  const [teachers, setTeachers] = useState([]);
  const [subjectsList, setSubjectsList] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const { city, geolocationLoading } = useGeolocation('Sabadell');
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [error, setError] = useState(null);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);

  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = async () => {
    try {
      const response = await get('subjects');
      setSubjectsList(response);
    } catch (error) {
      console.error('Error fetching subjects:', error);
      setError('Error al cargar las materias.');
    }
  };

  useEffect(() => {
    if (!geolocationLoading) {
      setFilters((prevFilters) => ({ ...prevFilters, city }));
    }
  }, [city, geolocationLoading]);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      fetchTeachers(filters);
    }
  }, [filters]);

  const fetchTeachers = async (filters) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();

      // Disponibilidad (Ciudad)
      if (filters.city) {
        queryParams.append('city', filters.city);
      }

      // Rating
      if (filters.rating) {
        queryParams.append('rating', filters.rating);
      }

      // Categoría
      if (filters.category) {
        queryParams.append('category', filters.category);
      }

      // Subcategoría
      if (filters.subCategory) {
        queryParams.append('subCategory', filters.subCategory);
      }

      // Materia (IDs y Nombre)
      if (filters.subjects && filters.subjects.length > 0) {
        filters.subjects.forEach(subjectId => {
          queryParams.append('subjects', subjectId.toString());
        });
      }
      if (filters.subjectName) {
        queryParams.append('subjectName', filters.subjectName);
      }

      // Precio Mínimo y Máximo
      if (filters.priceMin) {
        queryParams.append('priceMin', filters.priceMin);
      }
      if (filters.priceMax) {
        queryParams.append('priceMax', filters.priceMax);
      }

      // Paginación (agregar con valores por defecto si no están presentes)
      queryParams.append('page', filters.page ? filters.page : '1');
      queryParams.append('limit', filters.limit ? filters.limit : '10');

      const response = await get(`users/teachers?${queryParams.toString()}`);
      setTeachers(response.data || []);
    } catch (error) {
      setError('Error al cargar los profesores.');
      setTeachers([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (searchFilters) => {
    setFilters({
      ...searchFilters,
      page: 1,
      limit: 10,
    });
  };

  const handleStartChat = async (teacher) => {
    try {
      if (!user || !user.userId) {
        console.error('User ID is undefined');
        return;
      }
      const newChat = await post('chats', {
        user1Id: user.userId,
        user2Id: teacher.user.id,
      });
      setChats([...chats, newChat]);
      setSelectedChat(newChat.id);
      setSelectedRecipient(teacher.user.id);

      // Navegar a la página de chat y pasar el ID del chat
      navigate('/chat', { state: { chatId: newChat.id } });
    } catch (error) {
      console.error('Failed to start chat:', error);
    }
  };

  const closeChat = () => {
    setSelectedChat(null);
    setSelectedRecipient(null);
  };


  const handleOpenReviewModal = (teacher) => {
    setSelectedTeacher(teacher);
  };

  const handleCloseReviewModal = () => {
    setSelectedTeacher(null);
  };

  const handleReviewSubmitted = () => {
    // Refrescar la lista de profesores después de una reseña
    fetchTeachers(filters);
  };


  return (
    <div className="min-h-screen bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white p-10">
      <h1 className="text-4xl font-bold mb-4">Lista de Profesores</h1>
      <FilterPanel
        subjectsList={subjectsList}
        onSearch={handleSearch}
        initialFilters={filters}
      />
      {loading ? (
        <p>Cargando profesores...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : teachers.length === 0 ? (
        <p>No se encontraron profesores con los filtros seleccionados.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {teachers.map((teacher) => (
            <TeacherCard
              key={teacher.id}
              teacher={teacher}
              handleOpenReviewModal={handleOpenReviewModal}
              handleStartChat={handleStartChat}
            />
          ))}
        </div>
      )}
      {selectedTeacher && (
        <ReviewModal
          teacherId={selectedTeacher.user.id} // Usamos user.id en lugar de teacher.id
          isOpen={!!selectedTeacher}
          onClose={handleCloseReviewModal}
          onReviewSubmitted={handleReviewSubmitted}
        />
      )}
    </div>
  );
}

export default TeachersList;
