// src/components/admin/CategoryItem.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import CategoryForm from './CategoryForm';
import Swal from 'sweetalert2';

function CategoryItem({ category, onUpdate, onDelete }) {
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = async (updatedCategory) => {
        try {
            // Ahora, solo pasamos los datos actualizados sin el id
            await onUpdate(updatedCategory);
            Swal.fire('Éxito', 'Materia actualizada correctamente.', 'success');
            setIsEditing(false);
        } catch (err) {
            Swal.fire('Error', err.message || 'No se pudo actualizar la materia.', 'error');
        }
    };

    const handleDelete = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: `¿Quieres eliminar la materia "${category.name}"?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                onDelete(category.id);
                Swal.fire('Eliminado!', 'La materia ha sido eliminada.', 'success');
            }
        });
    };

    return (
        <>
            <tr>
                <td className="py-2 px-4 border-b">{category.category}</td>
                <td className="py-2 px-4 border-b">{category.subCategory}</td>
                <td className="py-2 px-4 border-b">{category.speciality}</td>
                <td className="py-2 px-4 border-b">{category.name}</td>
                <td className="py-2 px-4 border-b text-center">
                    <button
                        onClick={() => setIsEditing(true)}
                        className="text-blue-500 hover:text-blue-700 mr-2"
                        title="Editar"
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                        onClick={handleDelete}
                        className="text-red-500 hover:text-red-700"
                        title="Eliminar"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </td>
            </tr>

            {/* Modal de Edición */}
            {isEditing && (
                <CategoryForm
                    initialData={category}
                    onSubmit={handleEdit}
                    onCancel={() => setIsEditing(false)}
                />
            )}
        </>
    );
}

export default CategoryItem;
