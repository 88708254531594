// src/pages/admin/SubjectsPage.jsx
import React, { useState } from 'react';
import useSubjects from '../../hooks/useSubjects';
import Swal from 'sweetalert2';
import CategoryForm from '../../components/admin/CategoryForm';
import CategoryItem from '../../components/admin/CategoryItem';
import CSVUpload from '../../components/admin/CSVUpload';

function SubjectsPage() {
    const {
        subjects,
        loading,
        error,
        createSubject,
        updateSubject,
        deleteSubject,
        uploadSubjectsCSV,
    } = useSubjects();

    const [isFormOpen, setIsFormOpen] = useState(false);
    const [editingSubject, setEditingSubject] = useState(null);

    // Manejo de errores global
    React.useEffect(() => {
        if (error) {
            Swal.fire('Error', error, 'error');
        }
    }, [error]);

    const handleCreate = async (subjectData) => {
        try {
            await createSubject(subjectData);
            Swal.fire('Éxito', 'Materia creada correctamente.', 'success');
            setIsFormOpen(false);
            // La lista se actualiza automáticamente al agregar al estado en createSubject
        } catch (err) {
            Swal.fire('Error', err.message || 'No se pudo crear la materia.', 'error');
        }
    };

    const handleUpdate = async (id, updatedData) => {
        try {
            await updateSubject(id, updatedData);
            Swal.fire('Éxito', 'Materia actualizada correctamente.', 'success');
            setEditingSubject(null);
            // La lista se actualiza automáticamente al modificar el estado en updateSubject
        } catch (err) {
            Swal.fire('Error', err.message || 'No se pudo actualizar la materia.', 'error');
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteSubject(id);
            Swal.fire('Éxito', 'Materia eliminada correctamente.', 'success');
            // La lista se actualiza automáticamente al eliminar del estado en deleteSubject
        } catch (err) {
            Swal.fire('Error', err.message || 'No se pudo eliminar la materia.', 'error');
        }
    };

    const handleUploadCSVSuccess = async () => {
        Swal.fire('Éxito', 'Materias importadas correctamente desde el CSV.', 'success');
        // La lista se actualiza automáticamente después de subir el CSV en uploadSubjectsCSV
    };

    const handleUploadCSVError = (error) => {
        Swal.fire('Error', error.message || 'No se pudo importar las materias desde el CSV.', 'error');
    };

    if (loading) {
        return <p className="text-center mt-10">Cargando materias...</p>;
    }

    return (
        <div className="container mx-auto p-6">
            <div className="flex flex-col md:flex-row justify-between items-center mb-6">
                <h2 className="text-3xl font-bold mb-4 md:mb-0">Gestión de Materias</h2>
                <div className="flex space-x-2">
                    <button
                        onClick={() => setIsFormOpen(true)}
                        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded shadow"
                    >
                        Crear Materia
                    </button>
                    <CSVUpload
                        onUploadSuccess={handleUploadCSVSuccess}
                        onUploadError={handleUploadCSVError}
                    />
                </div>
            </div>

            {/* Tabla de Materias */}
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border rounded-lg shadow-md">
                    <thead>
                        <tr>
                            <th className="py-3 px-6 border-b bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Categoría</th>
                            <th className="py-3 px-6 border-b bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Subcategoría</th>
                            <th className="py-3 px-6 border-b bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Especialidad</th>
                            <th className="py-3 px-6 border-b bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Nombre</th>
                            <th className="py-3 px-6 border-b bg-gray-200 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subjects.length === 0 ? (
                            <tr>
                                <td colSpan="4" className="py-4 px-6 text-center text-gray-500">
                                    No hay materias registradas.
                                </td>
                            </tr>
                        ) : (
                            subjects.map((subject) => (
                                <CategoryItem
                                    key={subject.id}
                                    category={subject}
                                    onUpdate={(updatedData) => handleUpdate(subject.id, updatedData)}
                                    onDelete={handleDelete}
                                />
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            {/* Modal para Crear Materia */}
            {isFormOpen && (
                <CategoryForm
                    onSubmit={handleCreate}
                    onCancel={() => setIsFormOpen(false)}
                />
            )}

            {/* Modal para Editar Materia */}
            {editingSubject && (
                <CategoryForm
                    initialData={editingSubject}
                    onSubmit={(updatedData) => handleUpdate(editingSubject.id, updatedData)}
                    onCancel={() => setEditingSubject(null)}
                />
            )}
        </div>
    );

}

export default SubjectsPage;
