// src/pages/Admin/StudentsPage.js
import React from 'react';

function StudentsPage() {
    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Gestión de Alumnos</h2>
            <p className="text-gray-700">Aquí podrás ver, crear, editar y eliminar alumnos.</p>

            {/* Placeholder para futuras implementaciones */}
            <div className="mt-6">
                <p className="text-center text-gray-500">Funcionalidad en desarrollo...</p>
            </div>
        </div>
    );
}

export default StudentsPage;
