// helpers/api/Helper.js

const baseUrl = process.env.REACT_APP_BASE_API_URL;

async function fetchApi(endpoint, options = {}) {
    const url = new URL(`${baseUrl}/${endpoint}`);

    // Asegúrate de que esta es la clave correcta utilizada para almacenar el token
    const storedData = localStorage.getItem('auth-storage-repaso-pro'); // Cambia a 'auth-storage-petitprime' si es necesario
    const parsedData = storedData ? JSON.parse(storedData) : null;

    // Extraer el token del almacenamiento
    const token = parsedData?.state?.token;

    // Inicializar los headers
    const headers = new Headers();

    // Si existe el token, añadir el encabezado Authorization
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }

    // Detectar si el body es FormData
    const isFormData = options.body instanceof FormData;

    if (!isFormData) {
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
    }

    // Consolidar las opciones de la solicitud
    const requestOptions = {
        ...options,
        headers,
    };

    // Incluir el body solo si está proporcionado y el método no es GET o DELETE
    if (options.body && !['GET', 'DELETE'].includes(options.method)) {
        if (isFormData) {
            requestOptions.body = options.body; // No modificar FormData
        } else {
            requestOptions.body = JSON.stringify(options.body);
        }
    }

    try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            // Manejar diferentes tipos de errores
            let errorMessage = 'Something went wrong';
            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                const errorData = await response.json();
                errorMessage = errorData.message || errorMessage;
            } else {
                const errorText = await response.text();
                errorMessage = errorText || errorMessage;
            }
            throw new Error(errorMessage);
        }

        // Si la respuesta tiene contenido, retornarla como JSON; de lo contrario, retornar null
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
            return response.json();
        } else {
            return null;
        }
    } catch (error) {
        console.error('Fetch API Error:', error);
        throw error;
    }
}

export const get = (endpoint) => fetchApi(endpoint, { method: 'GET' });
export const post = (endpoint, body) => fetchApi(endpoint, { method: 'POST', body });
export const put = (endpoint, body) => fetchApi(endpoint, { method: 'PUT', body });
export const del = (endpoint, body) => fetchApi(endpoint, { method: 'DELETE', body });
export const patch = (endpoint, body) => fetchApi(endpoint, { method: 'PATCH', body });
