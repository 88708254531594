// src/pages/NotAuthorized.jsx
import React from 'react';
import { Link } from 'react-router-dom';

function NotAuthorized() {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md text-center">
                <h1 className="text-3xl font-bold mb-4">Acceso Denegado</h1>
                <p className="mb-6">No tienes permisos para acceder a esta página.</p>
                <Link to="/" className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700">
                    Volver al Inicio
                </Link>
            </div>
        </div>
    );
}

export default NotAuthorized;
