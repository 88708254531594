// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import ProtectedRoute from './components/common/ProtectedRoute';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import PublicRoute from './components/common/PublicRoute';
import TermsAndConditions from './pages/TermsAndConditions';
import CookiePolicyPage from './pages/CookiePolicyPage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentResult from './pages/PaymentResult';
import Settings from './pages/profile/Settings';
import ResetPassword from './pages/auth/ResetPassword';
import ChangePassword from './pages/auth/ChangePassword';
import EmailVerification from './pages/auth/EmailVerification';
import RegisterStudent from './pages/auth/RegisterStudent';
import RegisterTeacher from './pages/auth/RegisterTeacher';
import TeachersList from './pages/teacher/TeachersList';
import TeacherProfilePage from './pages/profile/TeacherProfilePage';
import TeachersDashboard from './pages/teacher/TeachersDashboard';
import EditProfilePage from './pages/profile/EditProfilePage';
import useAuthStore from './store/authStore';
import ChatPage from './pages/chat/ChatPage';
import TeacherDetails from './pages/teacher/TeacherDetails';
import AdminLayout from './components/admin/AdminLayout';
import CategoriesPage from './pages/admin/SubjectsPage';
import StudentsPage from './pages/admin/StudentsPage';
import TeachersPage from './pages/admin/TeachersPage';
import NotAuthorized from './pages/NotAuthorized';
import SubjectsPage from './pages/admin/SubjectsPage';

const stripePub = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePub);

function App() {
  const token = useAuthStore((state) => state.token);

  return (
    <Router>
      <Header />
      <main className="flex-grow flex flex-col">
        <Elements stripe={stripePromise}>
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register-student" element={<RegisterStudent />} />
              <Route path="/register-teacher" element={<RegisterTeacher />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/verify" element={<EmailVerification />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={['teacher']} />}>
              <Route path="/dashboard-teacher" element={<TeachersDashboard />} />
              <Route path="/teacher-profile/edit" element={<EditProfilePage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={['teacher', 'student']} />}>
              <Route path="/dashboard-student" element={<TeachersList />} />
              <Route path="/users/teachers/user/:userId" element={<TeacherDetails />} />
              <Route path="/teacher/:id" element={<TeacherProfilePage />} />
              <Route path="/chat" element={<ChatPage />} />
              <Route path="/settings" element={<Settings />} />
            </Route>

            {/* Rutas Administrativas */}
            <Route
              path="/admin/*"
              element={
                <ProtectedRoute allowedRoles={['admin']}>
                  <AdminLayout />
                </ProtectedRoute>
              }
            >
              {/* Rutas Anidadas Bajo /admin */}
              <Route index element={<Navigate to="subjects" replace />} />
              <Route path="subjects" element={<SubjectsPage />} />
              <Route path="teachers" element={<TeachersPage />} />
              <Route path="students" element={<StudentsPage />} />
              {/* Ruta comodín para redirigir a una ruta válida */}
              <Route path="*" element={<Navigate to="subjects" replace />} />
            </Route>

            {/* Página de "No Autorizado" */}
            <Route path="/not-authorized" element={<NotAuthorized />} />

            <Route path="/payment-result" element={<PaymentResult />} />
            <Route path="/terminos" element={<TermsAndConditions />} />
            <Route path="/politica-de-cookies" element={<CookiePolicyPage />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Elements>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
