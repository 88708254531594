// src/components/admin/CSVUpload.jsx
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';
import Papa from 'papaparse';
import Modal from 'react-modal';
import { post } from '../../helpers/apiHelper'; // Asegúrate de que la ruta sea correcta

Modal.setAppElement('#root');

function CSVUpload({ onUploadSuccess, onUploadError }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [previewData, setPreviewData] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: '.csv',
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const selectedFile = acceptedFiles[0];
                setFile(selectedFile);
                parseCSV(selectedFile);
            }
        },
    });

    const parseCSV = (file) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                // Verificar si existe la columna 'speciality'
                const hasSpeciality = results.meta.fields.includes('speciality');
                setPreviewData(results.data.map(row => ({
                    ...row,
                    speciality: hasSpeciality ? row.speciality.trim() : undefined,
                })));
                setError(null);
            },
            error: function (err) {
                setError('Error al parsear el archivo CSV.');
                setPreviewData([]);
            },
        });
    };

    const handleUpload = async () => {
        if (!file) {
            Swal.fire('Error', 'Por favor, selecciona un archivo CSV.', 'error');
            return;
        }

        // Validar que haya datos para subir
        if (previewData.length === 0) {
            Swal.fire('Error', 'El archivo CSV está vacío o mal formateado.', 'error');
            return;
        }

        setUploading(true);
        try {
            const formData = new FormData();
            formData.append('file', file);

            // Supongamos que tu endpoint para subir CSV es 'subjects/upload-csv'
            await post('subjects/upload-csv', formData);

            Swal.fire('Éxito', 'CSV procesado correctamente.', 'success');
            handleCloseModal();

            if (onUploadSuccess) onUploadSuccess(); // Llamamos sin parámetros ya que fetchSubjects ya actualiza la lista
        } catch (err) {
            Swal.fire('Error', err.message || 'Error al procesar el archivo CSV.', 'error');
            if (onUploadError) onUploadError(err);
        } finally {
            setUploading(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setFile(null);
        setPreviewData([]);
        setError(null);
    };

    return (
        <>
            {/* Botón de Carga de CSV */}
            <button
                onClick={() => setIsModalOpen(true)}
                className="flex items-center px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700"
                title="Subir CSV de Materias"
            >
                <FontAwesomeIcon icon={faUpload} className="mr-1" />
                CSV
            </button>

            {/* Modal de Carga y Previsualización */}
            {isModalOpen && (
                <Modal
                    isOpen={true}
                    onRequestClose={handleCloseModal}
                    contentLabel="Subir CSV de Materias"
                    className="modal bg-white p-6 rounded-lg shadow-lg max-w-2xl mx-auto my-10 overflow-auto"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
                >
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold">Subir CSV de Materias</h2>
                        <button onClick={handleCloseModal} className="text-gray-600 hover:text-gray-800">
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <div {...getRootProps()} className={`flex items-center justify-center border-2 border-dashed rounded-md p-4 cursor-pointer ${isDragActive ? 'border-green-500 bg-green-50' : 'border-gray-300'
                        }`}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>¡Suelta el archivo aquí!</p>
                        ) : (
                            <p>
                                Arrastra y suelta un archivo CSV aquí, o haz clic para seleccionar uno.
                            </p>
                        )}
                    </div>
                    {error && <p className="text-red-500 mt-2">{error}</p>}
                    {previewData.length > 0 && (
                        <div className="mt-6">
                            <h3 className="text-xl font-semibold mb-2">Previsualización de Datos</h3>
                            <div className="overflow-x-auto">
                                <table className="min-w-full bg-white border">
                                    <thead>
                                        <tr>
                                            {Object.keys(previewData[0]).map((header, index) => (
                                                <th
                                                    key={index}
                                                    className="py-2 px-4 border-b bg-gray-200 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                                                >
                                                    {header}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {previewData.slice(0, 10).map((row, rowIndex) => (
                                            <tr key={rowIndex} className="border-t">
                                                {Object.values(row).map((value, colIndex) => (
                                                    <td key={colIndex} className="py-2 px-4 text-sm text-gray-700">
                                                        {value}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {previewData.length > 10 && (
                                    <p className="text-sm text-gray-600 mt-2">Mostrando las primeras 10 filas de {previewData.length}.</p>
                                )}
                            </div>
                            <div className="flex justify-end mt-4 space-x-2">
                                <button
                                    onClick={handleCloseModal}
                                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                                >
                                    Cancelar
                                </button>
                                <button
                                    onClick={handleUpload}
                                    disabled={uploading}
                                    className="flex items-center px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 disabled:bg-green-400"
                                >
                                    <FontAwesomeIcon icon={faUpload} className="mr-2" />
                                    {uploading ? 'Subiendo...' : 'Subir CSV'}
                                </button>
                            </div>
                        </div>
                    )}
                </Modal>
            )}
        </>
    );

}

export default CSVUpload;
