// src/components/StarRating.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

function StarRating({ rating, onRatingChange }) {
    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <FontAwesomeIcon
                    key={i}
                    icon={faStar}
                    className={
                        i <= rating ? 'text-yellow-400 cursor-pointer' :
                            'text-gray-300 cursor-pointer'
                    }
                    onClick={() => onRatingChange(i)}
                />
            );
        }
        return stars;
    };

    return <div className="flex">{renderStars()}</div>;
}

export default StarRating;
