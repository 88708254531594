// src/components/FilterPanel.jsx
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { provinciasESP } from '../data/provinciasESP';
import { municipiosESP } from '../data/municipiosESP';
import { get } from '../helpers/apiHelper'; // Asegúrate de tener una función GET en apiHelper

const FilterPanel = ({ subjectsList, onSearch, initialFilters = {} }) => {
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [availableCities, setAvailableCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(
    initialFilters.city ? { value: initialFilters.city, label: initialFilters.city } : null
  );
  const [rating, setRating] = useState(initialFilters.rating || '');
  const [category, setCategory] = useState(initialFilters.category || null);
  const [subCategory, setSubCategory] = useState(initialFilters.subCategory || null);
  const [subject, setSubject] = useState(initialFilters.subject ? { value: initialFilters.subject, label: initialFilters.subjectName } : null);
  const [priceMin, setPriceMin] = useState(initialFilters.priceMin || '');
  const [priceMax, setPriceMax] = useState(initialFilters.priceMax || '');
  const [speciality, setSpeciality] = useState(initialFilters.speciality || null);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [subjectsOptions, setSubjectsOptions] = useState([]);

  // Fetch unique categories on mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await get('subjects'); // Endpoint: GET /subjects
        const uniqueCategories = [...new Set(data.map(subject => subject.category))];
        setCategories(uniqueCategories.map(cat => ({ value: cat, label: cat })));
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  // Update subCategories when category changes
  useEffect(() => {
    if (category) {
      const filteredSubCategories = subjectsList
        .filter(subject => subject.category === category.value)
        .map(subject => subject.subCategory);
      const uniqueSubCategories = [...new Set(filteredSubCategories)];
      setSubCategories(uniqueSubCategories.map(sub => ({ value: sub, label: sub })));
      setSubCategory(null);
      setSpeciality(null);
      setSubjectsOptions([]);
    } else {
      setSubCategories([]);
      setSubCategory(null);
      setSpeciality(null);
      setSubjectsOptions([]);
    }
  }, [category, subjectsList]);

  // Update specialities when subCategory changes
  useEffect(() => {
    if (subCategory) {
      const filteredSpecialities = subjectsList
        .filter(subject => subject.category === category.value && subject.subCategory === subCategory.value && subject.speciality)
        .map(subject => subject.speciality);
      const uniqueSpecialities = [...new Set(filteredSpecialities)];
      setSpecialities(uniqueSpecialities.map(spec => ({ value: spec, label: spec })));
      setSpeciality(null);
    } else {
      setSpecialities([]);
      setSpeciality(null);
    }
  }, [subCategory, category, subjectsList]);

  // Update subjects when speciality changes
  useEffect(() => {
    if (subCategory) {
      let filteredSubjects = subjectsList.filter(subject => subject.category === category.value && subject.subCategory === subCategory.value);

      if (speciality) {
        filteredSubjects = filteredSubjects.filter(subject => subject.speciality === speciality.value);
      }

      setSubjectsOptions(filteredSubjects.map(subject => ({ value: subject.id, label: subject.name })));
      setSubject(null);
    } else {
      setSubjectsOptions([]);
      setSubject(null);
    }
  }, [subCategory, speciality, category, subjectsList]);

  useEffect(() => {
    if (selectedProvince) {
      const cities = municipiosESP
        .filter((municipio) => municipio.id === selectedProvince.label)
        .map((municipio) => ({ value: municipio.nm, label: municipio.nm }));
      setAvailableCities(cities);
    } else {
      setAvailableCities([]);
    }
  }, [selectedProvince]);

  const handleSearchClick = () => {
    const filters = {};
    if (selectedCity) filters.city = selectedCity.value;
    if (rating) filters.rating = rating;
    if (category) filters.category = category.value;
    if (subCategory) filters.subCategory = subCategory.value;
    if (subject) {
      filters.subjects = [Number(subject.value)];
      filters.subjectName = subject.label;
    }
    if (speciality) filters.speciality = speciality.value;
    if (priceMin || priceMax) {
      filters.priceMin = priceMin;
      filters.priceMax = priceMax;
    }
    onSearch(filters);
  };

  const provinceOptions = provinciasESP
    .sort((a, b) => a.nm.localeCompare(b.nm))
    .map((provincia) => ({
      value: provincia.id,
      label: provincia.nm,
    }));

  return (
    <div className="mb-4 flex flex-wrap gap-2">
      {/* Provincia */}
      <Select
        options={provinceOptions}
        value={selectedProvince}
        onChange={(selectedOption) => {
          setSelectedProvince(selectedOption);
          setSelectedCity(null);
        }}
        placeholder="Seleccione Provincia"
        className="w-full sm:w-48 text-black"
      />

      {/* Municipio */}
      <Select
        options={availableCities}
        value={selectedCity}
        onChange={setSelectedCity}
        placeholder="Seleccione Municipio"
        className="w-full sm:w-48 text-black"
        isDisabled={!selectedProvince}
      />

      {/* Rating */}
      <select
        value={rating}
        onChange={(e) => setRating(e.target.value)}
        className="p-2 rounded bg-gray-200 text-gray-800 w-full sm:w-32"
      >
        <option value="">Seleccione Rating</option>
        {[5, 4, 3, 2, 1, 0].map((r) => (
          <option key={r} value={r}>
            {r} Estrellas o más
          </option>
        ))}
      </select>

      {/* Categoría */}
      <Select
        options={categories}
        value={category}
        onChange={setCategory}
        placeholder="Seleccione Categoría"
        className="w-full sm:w-48 text-black"
        isClearable
      />

      {/* Subcategoría */}
      {subCategories.length > 0 && (
        <Select
          options={subCategories}
          value={subCategory}
          onChange={setSubCategory}
          placeholder="Seleccione Subcategoría"
          className="w-full sm:w-48 text-black"
          isClearable
        />
      )}

      {/* Especialidad */}
      {specialities.length > 0 && (
        <Select
          options={specialities}
          value={speciality}
          onChange={setSpeciality}
          placeholder="Seleccione Especialidad"
          className="w-full sm:w-48 text-black"
          isClearable
        />
      )}

      {/* Materia */}
      {subjectsOptions.length > 0 && (
        <Select
          options={subjectsOptions}
          value={subject}
          onChange={setSubject}
          placeholder="Seleccione Materia"
          className="w-full sm:w-48 text-black"
          isClearable
        />
      )}

      {/* Precio Mínimo */}
      <input
        type="number"
        inputMode="numeric"
        value={priceMin}
        onChange={(e) => {
          const value = e.target.value;
          if (/^\d*$/.test(value)) {
            setPriceMin(value);
          }
        }}
        className="p-2 rounded bg-gray-200 text-gray-800 w-full sm:w-24"
        placeholder="Precio Min (€)"
        min="0"
        step="1"
      />

      {/* Precio Máximo */}
      <input
        type="number"
        inputMode="numeric"
        value={priceMax}
        onChange={(e) => {
          const value = e.target.value;
          if (/^\d*$/.test(value)) {
            setPriceMax(value);
          }
        }}
        className="p-2 rounded bg-gray-200 text-gray-800 w-full sm:w-24"
        placeholder="Precio Max (€)"
        min="0"
        step="1"
      />

      {/* Botón de Búsqueda */}
      <button
        onClick={handleSearchClick}
        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700 w-full sm:w-32"
      >
        Buscar
      </button>
    </div>
  );
};

FilterPanel.propTypes = {
  subjectsList: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  initialFilters: PropTypes.object,
};

export default FilterPanel;
