// src/pages/EditProfilePage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { get, post } from '../../helpers/apiHelper';
import useAuthStore from '../../store/authStore';
import ProvinceSelector from '../../components/auth/ProvinceSelector';
import SubjectSelector from '../../components/auth/SubjectSelector';
import AvatarSelector from '../../components/auth/AvatarSelector';
import AvatarModal from '../../components/profile/AvatarModal';
import SubjectsSelectionModal from '../../components/auth/SubjectsSelectionModal'; // Asegúrate de tener este componente
import { provinciasESP } from '../../data/provinciasESP';
import { municipiosESP } from '../../data/municipiosESP';

const EditProfilePage = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();

  // Estados para el formulario
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [hourlyRate, setHourlyRate] = useState('');
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]); // Array de { province, cities }
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [loading, setLoading] = useState(true);

  // Estados temporales para agregar nuevas ubicaciones
  const [tempProvince, setTempProvince] = useState(null);
  const [tempCities, setTempCities] = useState([]);

  // Estado para Avatar Modal
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);

  // Estado para Subjects Selection Modal
  const [isSubjectsModalOpen, setIsSubjectsModalOpen] = useState(false);

  // Avatares disponibles
  const avatars = Array.from({ length: 14 }, (_, i) => ({
    value: `avatar${i + 1}.webp`,
    label: `Avatar ${i + 1}`,
    url: `/avatars/avatar${i + 1}.webp`,
  }));

  // Fetch profile data cuando el componente se monta
  useEffect(() => {
    if (user && user.userId) {
      fetchProfileData(user.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Añadir nueva ubicación cuando tempProvince y tempCities cambian
  useEffect(() => {
    if (tempProvince && tempCities.length > 0) {
      handleAddLocation(tempProvince, tempCities);
      setTempProvince(null);
      setTempCities([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempProvince, tempCities]);

  const fetchProfileData = async (teacherId) => {
    setLoading(true);
    try {
      const profileData = await get(`users/teachers/user/${teacherId}`);

      setName(profileData.user.name);
      setBio(profileData.bio);
      setHourlyRate(profileData.hourly_rate.toString());
      setSelectedAvatar(profileData.user.profileImage);

      // Configurar las materias seleccionadas
      setSelectedSubjects(
        profileData.subjects.map((sub) => ({
          value: sub.id,
          label: `${sub.category} - ${sub.subCategory}${sub.speciality ? ` - ${sub.speciality}` : ''} - ${sub.name}`,
          speciality: sub.speciality || '',
        }))
      );

      // Configurar las ciudades seleccionadas
      const cities = profileData.availability
        .split(',')
        .map((city) => city.trim())
        .filter((city) => city !== '');

      // Map cities to provinces usando .forEach
      const mappedLocations = [];
      cities.forEach((city) => {
        const municipio = municipiosESP.find(
          (m) => m.nm.toLowerCase() === city.toLowerCase()
        );
        if (municipio) {
          // Comparar el nombre de la provincia en lugar del ID
          const province = provinciasESP.find(
            (p) => p.nm.toLowerCase() === municipio.id.toLowerCase()
          );
          if (province) {
            const provinceOption = { value: province.id, label: province.nm };
            const cityOption = { value: city, label: city };

            const existingProvince = mappedLocations.find(
              (loc) => loc.province.value === province.id
            );

            if (existingProvince) {
              // Evitar ciudades duplicadas
              if (!existingProvince.cities.some((c) => c.value === city)) {
                existingProvince.cities.push(cityOption);
              }
            } else {
              mappedLocations.push({
                province: provinceOption,
                cities: [cityOption],
              });
            }
          } else {
            console.warn(`No se encontró la provincia para el municipio: ${municipio.id}`);
          }
        } else {
          console.warn(`No se encontró el municipio: ${city}`);
        }
      });

      setSelectedLocations(mappedLocations);
    } catch (error) {
      console.error('Failed to fetch profile data:', error.message);
      Swal.fire('Error', 'Failed to load profile data. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    // Validaciones
    if (!hourlyRate || isNaN(parseFloat(hourlyRate)) || Number(hourlyRate) <= 0) {
      Swal.fire('Error', 'Por favor, ingresa una tarifa por hora válida.', 'error');
      return;
    }

    if (selectedSubjects.length === 0) {
      Swal.fire('Error', 'Por favor, selecciona al menos una materia.', 'error');
      return;
    }

    const updatedTeacher = {
      name,
      bio,
      hourly_rate: parseFloat(hourlyRate),
      availability: selectedLocations.flatMap(loc => loc.cities.map(city => city.value)).join(', '),
      subjects: selectedSubjects.map((sub) => sub.value),
      profileImage: selectedAvatar,
    };

    try {
      const response = await post(`users/teachers/${user.userId}/update`, updatedTeacher);
      Swal.fire('Éxito', 'Perfil actualizado correctamente.', 'success');
      navigate('/teacher-profile');
    } catch (error) {
      console.error('Failed to update profile:', error.message);
      Swal.fire('Error', `El registro falló. ${error.response?.data?.message || error.message}`, 'error');
    }
  };

  // Función para eliminar una ciudad específica dentro de una provincia
  const handleRemoveCity = (cityToRemove, province) => {
    setSelectedLocations(selectedLocations.map((loc) => {
      if (loc.province.value === province.value) {
        const updatedCities = loc.cities.filter((city) => city.value !== cityToRemove.value);
        return { ...loc, cities: updatedCities };
      }
      return loc;
    }).filter((loc) => loc.cities.length > 0)); // Eliminar provincia si no quedan ciudades
  };

  // Función para eliminar una provincia completa
  const handleRemoveProvince = (provinceToRemove) => {
    setSelectedLocations(selectedLocations.filter((loc) => loc.province.value !== provinceToRemove.value));
  };

  // Callback para añadir una nueva ubicación
  const handleAddLocation = (province, cities) => {
    if (!province || cities.length === 0) return;

    const existingLocation = selectedLocations.find(
      (loc) => loc.province.value === province.value
    );

    if (existingLocation) {
      // Añadir nuevas ciudades evitando duplicados
      const updatedLocations = selectedLocations.map((loc) => {
        if (loc.province.value === province.value) {
          const newCities = cities.filter(
            (city) => !loc.cities.some((c) => c.value === city.value)
          );
          return {
            ...loc,
            cities: [...loc.cities, ...newCities],
          };
        }
        return loc;
      });
      setSelectedLocations(updatedLocations);
    } else {
      // Añadir nueva provincia y sus ciudades
      setSelectedLocations([...selectedLocations, { province, cities }]);
    }
  };

  // Abrir el modal de avatar
  const openAvatarModal = () => {
    setIsAvatarModalOpen(true);
  };

  // Cerrar el modal de avatar
  const closeAvatarModal = () => {
    setIsAvatarModalOpen(false);
  };

  // Seleccionar un avatar
  const selectAvatar = (avatar) => {
    setSelectedAvatar(avatar.url);
    closeAvatarModal();
  };

  // Abrir el modal de selección de materias
  const openSubjectsModal = () => {
    setIsSubjectsModalOpen(true);
  };

  // Cerrar el modal de selección de materias
  const closeSubjectsModal = () => {
    setIsSubjectsModalOpen(false);
  };

  const handleRemoveSubject = (subjectValue) => {
    setSelectedSubjects(selectedSubjects.filter(subject => subject.value !== subjectValue));
  };

  // Manejar la adición de materias desde el modal
  const handleAddSubjects = (data) => {
    const { selectedSubjectIds, speciality } = data;

    // Obtener las materias completas con los detalles desde el listado general
    const newSubjects = selectedSubjectIds.map(id => {
      const subject = subjectsList.find(sub => sub.id === id);
      if (subject) {
        return {
          value: subject.id,
          label: `${subject.category} - ${subject.subCategory}${subject.speciality ? ` - ${subject.speciality}` : ''} - ${subject.name}`,
          speciality: speciality || subject.speciality || '',
        };
      }
      return null;
    }).filter(sub => sub !== null);

    // Añadir las nuevas materias a la lista existente
    setSelectedSubjects(prev => [...prev, ...newSubjects]);

    // Cerrar el modal
    closeSubjectsModal();
  };

  // Estado para almacenar todos los subjects (para SubjectsSelectionModal)
  const [subjectsList, setSubjectsList] = useState([]);

  // Fetch all subjects for the modal
  useEffect(() => {
    const fetchAllSubjects = async () => {
      try {
        const data = await get('subjects'); // Endpoint: GET /subjects
        setSubjectsList(data);
      } catch (error) {
        console.error('Error fetching all subjects:', error);
        Swal.fire('Error', 'No se pudieron cargar las materias.', 'error');
      }
    };

    fetchAllSubjects();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white p-10">
      <h1 className="text-4xl font-bold mb-4">Edita tu Perfil</h1>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <p className="text-lg text-gray-600">Cargando datos del perfil...</p>
        </div>
      ) : (
        <div className="bg-white p-6 rounded-lg shadow-lg text-black">
          {/* Nombre */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Nombre:
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>

          {/* Biografía */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bio">
              Biografía:
            </label>
            <textarea
              id="bio"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>

          {/* Tarifa por Hora */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="hourlyRate">
              Tarifa por hora (€):
            </label>
            <input
              id="hourlyRate"
              name="hourlyRate"
              type="text"
              value={hourlyRate}
              onChange={(e) => {
                const input = e.target.value.replace(',', '.');
                if (/^\d*\.?\d{0,2}$/.test(input)) {
                  setHourlyRate(input);
                }
              }}
              placeholder="Tarifa por hora (€)"
              required
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
            />
          </div>

          {/* Ubicaciones */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Agregar Ubicación:</label>
            <ProvinceSelector
              selectedProvince={tempProvince}
              setSelectedProvince={setTempProvince}
              selectedCities={tempCities}
              setSelectedCities={setTempCities}
            />
          </div>

          {/* Lista de Ubicaciones Seleccionadas */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Ubicaciones Seleccionadas:</label>
            {selectedLocations.length > 0 ? (
              <div>
                {selectedLocations.map((loc) => (
                  <div key={loc.province.value} className="mb-2">
                    {/* Contenedor de la Provincia */}
                    <div className="flex items-center">
                      <span className="font-semibold">{loc.province.label}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveProvince(loc.province)}
                        className="text-red-500 hover:text-red-700 ml-2"
                      >
                        &times;
                      </button>
                    </div>
                    {/* Lista de Ciudades */}
                    <ul className="list-disc list-inside ml-4">
                      {loc.cities.map((city) => (
                        <li key={city.value} className="flex items-center">
                          <span>{city.label}</span>
                          <button
                            type="button"
                            onClick={() => handleRemoveCity(city, loc.province)}
                            className="text-red-500 hover:text-red-700 ml-2"
                          >
                            &times;
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-600">No hay ubicaciones seleccionadas.</p>
            )}
          </div>

          {/* Materias */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Materias:</label>
            <SubjectSelector
              selectedSubjects={selectedSubjects}
              setSelectedSubjects={setSelectedSubjects}
            />
          </div>

          {/* Lista de Materias Seleccionadas */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Materias Seleccionadas:</label>
            {selectedSubjects.length > 0 ? (
              <ul className="list-disc list-inside ml-4">
                {selectedSubjects.map((subject) => (
                  <li key={subject.value} className="flex items-center">
                    <span>{subject.label}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveSubject(subject.value)}
                      className="text-red-500 hover:text-red-700 ml-2"
                    >
                      &times;
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">No hay materias seleccionadas.</p>
            )}
          </div>

          {/* Botón para abrir el modal de selección de materias */}
          <div className="mb-4">
            <button
              type="button"
              onClick={openSubjectsModal}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Seleccionar Materias
            </button>
          </div>

          {/* Avatar */}
          <AvatarSelector selectedAvatar={selectedAvatar} openAvatarModal={openAvatarModal} />

          {/* Botón para guardar cambios */}
          <button
            onClick={handleSave}
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700 w-full mt-4"
          >
            Guardar
          </button>
        </div>
      )}

      {/* Modal de Avatar */}
      <AvatarModal
        isOpen={isAvatarModalOpen}
        onRequestClose={closeAvatarModal}
        avatars={avatars}
        onSelectAvatar={selectAvatar}
      />

      {/* Modal de Selección de Materias */}
      <SubjectsSelectionModal
        isOpen={isSubjectsModalOpen}
        onRequestClose={closeSubjectsModal}
        onSubmit={handleAddSubjects}
        subjects={subjectsList}
      />
    </div>
  );
};

export default EditProfilePage;
