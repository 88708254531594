// src/components/Header.js
import React, { useState } from 'react';
import useAuthStore from '../../store/authStore';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt, faComments, faBars, faTimes, faUserShield } from '@fortawesome/free-solid-svg-icons';
import useNotificationStore from '../../store/notificationStore';

function Header() {
  const { user, logout } = useAuthStore();
  const userRole = user?.role;
  const navigate = useNavigate();

  const totalUnreadMessages = useNotificationStore((state) => state.totalUnreadMessages);
  const [isPersonalView, setIsPersonalView] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para manejar el menú móvil

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleToggleView = () => {
    if (isPersonalView) {
      navigate('/dashboard-student');
    } else {
      navigate('/dashboard-teacher');
    }
    setIsPersonalView(!isPersonalView);
    setIsMenuOpen(false); // Cerrar el menú después de cambiar la vista
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo/Title */}
        <h1 className="text-2xl font-bold cursor-pointer">
          <Link to="/" className="hover:underline">RepasoPro</Link>
        </h1>

        {/* Botón del Menú Móvil */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="lg" />
          </button>
        </div>

        {/* Menú Desktop */}
        <div className="hidden md:flex items-center space-x-4">
          {userRole === 'teacher' && (
            <button
              onClick={handleToggleView}
              className="py-2 px-4 bg-yellow-500 hover:bg-yellow-600 rounded-full text-lg transition-colors duration-300"
            >
              {isPersonalView ? 'Buscar Profesores' : 'Mi Pantalla Personal'}
            </button>
          )}

          {userRole === 'admin' && (
            <Link
              to="/admin"
              className="py-2 px-4 bg-purple-500 hover:bg-purple-600 rounded-full text-lg transition-colors duration-300 flex items-center"
            >
              <FontAwesomeIcon icon={faUserShield} className="text-xl mr-2" />
              Administración
            </Link>
          )}

          {user ? (
            <>
              <Link to="/chat" className="relative py-2 px-4 bg-blue-500 hover:bg-blue-600 rounded-full text-lg transition-colors duration-300">
                <FontAwesomeIcon icon={faComments} className="text-xl" />
                {totalUnreadMessages > 0 && (
                  <span className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                    {totalUnreadMessages > 99 ? '+' : totalUnreadMessages}
                  </span>
                )}
              </Link>
              <Link to="/settings" className="py-2 px-4 bg-green-500 hover:bg-green-600 rounded-full text-lg transition-colors duration-300">
                <FontAwesomeIcon icon={faCog} className="text-xl" />
              </Link>
              <button onClick={handleLogout} className="py-2 px-4 bg-red-500 hover:bg-red-600 rounded-full text-lg transition-colors duration-300">
                <FontAwesomeIcon icon={faSignOutAlt} className="text-xl" />
              </button>
            </>
          ) : (
            <button onClick={() => navigate('/login')} className="bg-white text-green-600 font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out hover:bg-gray-200">
              Iniciar sesión
            </button>
          )}
        </div>
      </div>

      {/* Menú Móvil */}
      {isMenuOpen && (
        <div className="md:hidden bg-green-500 text-white p-4">
          <div className="flex flex-col space-y-2">
            {userRole === 'teacher' && (
              <button
                onClick={handleToggleView}
                className="py-2 px-4 bg-yellow-500 hover:bg-yellow-600 rounded-full text-lg transition-colors duration-300"
              >
                {isPersonalView ? 'Buscar Profesores' : 'Mi Pantalla Personal'}
              </button>
            )}

            {userRole === 'admin' && (
              <Link
                to="/admin"
                onClick={toggleMenu}
                className="py-2 px-4 bg-purple-500 hover:bg-purple-600 rounded-full text-lg transition-colors duration-300 flex items-center"
              >
                <FontAwesomeIcon icon={faUserShield} className="text-xl mr-2" />
                Administración
              </Link>
            )}

            {user ? (
              <>
                <Link
                  to="/chat"
                  onClick={toggleMenu}
                  className="relative py-2 px-4 bg-blue-500 hover:bg-blue-600 rounded-full text-lg transition-colors duration-300 flex items-center"
                >
                  <FontAwesomeIcon icon={faComments} className="text-xl mr-2" />
                  Chat
                  {totalUnreadMessages > 0 && (
                    <span className="ml-2 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                      {totalUnreadMessages > 99 ? '+' : totalUnreadMessages}
                    </span>
                  )}
                </Link>
                <Link
                  to="/settings"
                  onClick={toggleMenu}
                  className="py-2 px-4 bg-green-500 hover:bg-green-600 rounded-full text-lg transition-colors duration-300 flex items-center"
                >
                  <FontAwesomeIcon icon={faCog} className="text-xl mr-2" />
                  Configuración
                </Link>
                <button
                  onClick={() => {
                    handleLogout();
                    toggleMenu();
                  }}
                  className="py-2 px-4 bg-red-500 hover:bg-red-600 rounded-full text-lg transition-colors duration-300 flex items-center"
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-xl mr-2" />
                  Cerrar sesión
                </button>
              </>
            ) : (
              <button
                onClick={() => {
                  navigate('/login');
                  toggleMenu();
                }}
                className="bg-white text-green-600 font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all duration-300 ease-in-out hover:bg-gray-200"
              >
                Iniciar sesión
              </button>
            )}
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
