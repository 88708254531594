// src/hooks/useGeolocation.js
import { useState, useEffect } from 'react';

function useGeolocation(defaultCity = 'Sabadell') {
    const [city, setCity] = useState(null);
    const [geolocationLoading, setGeolocationLoading] = useState(true);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
        } else {
            setCity(defaultCity);
            setGeolocationLoading(false);
        }
    }, []);

    const handleSuccess = async (position) => {
        const { latitude, longitude } = position.coords;
        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
            const data = await response.json();
            const address = data.address;
            const detectedCity =
                address.town ||
                address.village ||
                address.city ||
                address.suburb ||
                address.hamlet ||
                address.neighbourhood ||
                defaultCity;

            setCity(detectedCity);
        } catch (error) {
            console.error('Error en geocodificación:', error);
            setCity(defaultCity);
        } finally {
            setGeolocationLoading(false);
        }
    };

    const handleError = (error) => {
        console.error('Error en geolocalización:', error);
        setCity(defaultCity);
        setGeolocationLoading(false);
    };

    return { city, geolocationLoading };
}

export default useGeolocation;
