// src/components/admin/AdminLayout.js
import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faChalkboardTeacher, faList, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function AdminLayout() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar */}
            <aside
                className={`fixed inset-y-0 left-0 w-64 bg-white shadow-md transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                    } transition-transform duration-300 ease-in-out md:translate-x-0 md:static md:inset-0 z-10`}
            >
                <div className="p-4 text-xl font-bold text-green-600">
                    Admin Panel
                </div>
                <nav className="mt-10">
                    <NavLink
                        to="subjects"
                        className={({ isActive }) =>
                            `flex items-center py-2 px-6 text-gray-700 hover:bg-green-100 hover:text-green-600 ${isActive ? 'bg-green-200 text-green-600' : ''
                            }`
                        }
                        onClick={() => setIsSidebarOpen(false)}
                    >
                        <FontAwesomeIcon icon={faList} className="mr-3" />
                        Materias
                    </NavLink>
                    <NavLink
                        to="teachers"
                        className={({ isActive }) =>
                            `flex items-center py-2 px-6 text-gray-700 hover:bg-green-100 hover:text-green-600 ${isActive ? 'bg-green-200 text-green-600' : ''
                            }`
                        }
                        onClick={() => setIsSidebarOpen(false)}
                    >
                        <FontAwesomeIcon icon={faChalkboardTeacher} className="mr-3" />
                        Profesores
                    </NavLink>
                    <NavLink
                        to="students"
                        className={({ isActive }) =>
                            `flex items-center py-2 px-6 text-gray-700 hover:bg-green-100 hover:text-green-600 ${isActive ? 'bg-green-200 text-green-600' : ''
                            }`
                        }
                        onClick={() => setIsSidebarOpen(false)}
                    >
                        <FontAwesomeIcon icon={faUsers} className="mr-3" />
                        Alumnos
                    </NavLink>
                </nav>
            </aside>

            {/* Botón para abrir/cerrar Sidebar en dispositivos móviles */}
            <button
                onClick={toggleSidebar}
                className="absolute top-4 left-4 md:hidden text-white bg-green-600 p-2 rounded-md z-20"
            >
                <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
            </button>

            {/* Main Content */}
            <div className="flex-1 p-6 ml-0 md:ml-64 overflow-auto">
                <Outlet />
            </div>
        </div>
    );
}

export default AdminLayout;
