// src/hooks/useSubjects.js
import { useState, useEffect, useCallback } from 'react';
import { get, post, put, del } from '../helpers/apiHelper';

function useSubjects() {
    const [subjects, setSubjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Función para obtener la lista de materias
    const fetchSubjects = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await get('subjects'); // Asegúrate de que el endpoint es correcto
            setSubjects(data);
        } catch (err) {
            setError(err.message || 'Error al obtener las materias.');
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchSubjects();
    }, [fetchSubjects]);

    // Función para crear una nueva materia
    const createSubject = async (subjectData) => {
        try {
            const newSubject = await post('subjects', subjectData); // Ajusta el endpoint según tu API
            setSubjects((prevSubjects) => [...prevSubjects, newSubject]);
        } catch (err) {
            throw err;
        }
    };

    // Función para actualizar una materia existente
    const updateSubject = async (id, updatedData) => {
        try {
            const updatedSubject = await put(`subjects/${id}`, updatedData); // Ajusta el endpoint según tu API
            setSubjects((prevSubjects) =>
                prevSubjects.map((subject) =>
                    subject.id === id ? updatedSubject : subject
                )
            );
        } catch (err) {
            throw err;
        }
    };

    // Función para eliminar una materia
    const deleteSubject = async (id) => {
        try {
            await del(`subjects/${id}`);
            setSubjects((prevSubjects) =>
                prevSubjects.filter((subject) => subject.id !== id)
            );
        } catch (err) {
            throw err;
        }
    };

    // Función para subir materias desde un CSV
    const uploadSubjectsCSV = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            await post('subjects/upload-csv', formData); // Ajusta el endpoint según tu API
            await fetchSubjects(); // Re-fetch después de subir CSV
        } catch (err) {
            throw err;
        }
    };

    return {
        subjects,
        loading,
        error,
        fetchSubjects, // Exportamos la función para re-fetching si es necesario
        createSubject,
        updateSubject,
        deleteSubject,
        uploadSubjectsCSV,
    };
}

export default useSubjects;
