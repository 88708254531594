// src/components/ChatItem.js
import React from 'react';
import useAuthStore from '../../store/authStore';

function ChatItem({ chat, onSelectChat }) {
    const { user } = useAuthStore();
    const otherUser =
        chat.user1.id === user.userId ? chat.user2 : chat.user1;
    const otherUserName = otherUser.name || 'Desconocido';
    const unreadMessagesCount = chat.unreadMessagesCount || 0;

    return (
        <li
            className="p-4 cursor-pointer hover:bg-gray-200 border-b border-gray-300"
            onClick={() => onSelectChat(chat)}
        >
            <div className="font-semibold">{otherUserName}</div>
            <div className="text-sm text-gray-600">
                {chat.messages && chat.messages.length > 0
                    ? chat.messages[chat.messages.length - 1].content
                    : 'No hay mensajes todavia'}
            </div>
            {unreadMessagesCount > 0 && (
                <span className="bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                    {unreadMessagesCount > 99 ? '+' : unreadMessagesCount}
                </span>
            )}
        </li>
    );
}

export default ChatItem;
