// src/components/auth/SubjectsSelectionModal.jsx
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Swal from 'sweetalert2';

function SubjectsSelectionModal({ isOpen, onRequestClose, onSubmit, subjects }) {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [availableSubCategories, setAvailableSubCategories] = useState([]);
    const [availableSpecialities, setAvailableSpecialities] = useState([]);
    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Resetear los campos cuando el modal se cierra
    useEffect(() => {
        if (!isOpen) {
            setSelectedCategory(null);
            setSelectedSubCategory(null);
            setAvailableSubCategories([]);
            setAvailableSpecialities([]);
            setSelectedSpeciality('');
            setSelectedSubjects([]);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && subjects.length > 0) {
            setIsLoading(true);
            try {
                // Agrupar las materias por categorías y subcategorías
                const categoriesMap = {};

                subjects.forEach(subject => {
                    const { category, subCategory, speciality, id, name } = subject;
                    if (!categoriesMap[category]) {
                        categoriesMap[category] = {};
                    }
                    if (!categoriesMap[category][subCategory]) {
                        categoriesMap[category][subCategory] = {
                            subjects: [],
                            specialities: new Set(),
                        };
                    }
                    categoriesMap[category][subCategory].subjects.push({
                        value: id,
                        label: name,
                        speciality: speciality || null,
                    });
                    if (speciality) {
                        categoriesMap[category][subCategory].specialities.add(speciality);
                    }
                });

                // Convertir el mapa a una lista de categorías con subcategorías y materias
                const nestedCategories = Object.keys(categoriesMap).map(cat => ({
                    value: cat,
                    label: cat,
                    subCategories: Object.keys(categoriesMap[cat]).map(subCat => ({
                        value: subCat,
                        label: subCat,
                        subjects: categoriesMap[cat][subCat].subjects,
                        specialities: Array.from(categoriesMap[cat][subCat].specialities),
                    })),
                }));

                setCategories(nestedCategories);
            } catch (error) {
                console.error('Error al procesar materias:', error);
                Swal.fire('Error', 'Error al procesar las materias.', 'error');
            } finally {
                setIsLoading(false);
            }
        } else {
            // Resetear estados al cerrar el modal
            setSelectedCategory(null);
            setSelectedSubCategory(null);
            setAvailableSubCategories([]);
            setAvailableSpecialities([]);
            setSelectedSpeciality('');
            setSelectedSubjects([]);
        }
    }, [isOpen, subjects]);

    // Actualizar subcategorías cuando cambia la categoría
    useEffect(() => {
        if (selectedCategory) {
            setAvailableSubCategories(selectedCategory.subCategories || []);
            setSelectedSubCategory(null);
            setAvailableSpecialities([]);
            setSelectedSpeciality('');
            setSelectedSubjects([]);
        } else {
            setAvailableSubCategories([]);
            setSelectedSubCategory(null);
            setAvailableSpecialities([]);
            setSelectedSpeciality('');
            setSelectedSubjects([]);
        }
    }, [selectedCategory]);

    // Actualizar especialidades cuando cambia la subcategoría
    useEffect(() => {
        if (selectedSubCategory) {
            const subCatObj = selectedCategory.subCategories.find(sub => sub.value === selectedSubCategory.value);
            setAvailableSpecialities(subCatObj.specialities || []);
            setSelectedSpeciality('');
            setSelectedSubjects([]);
        } else {
            setAvailableSpecialities([]);
            setSelectedSpeciality('');
            setSelectedSubjects([]);
        }
    }, [selectedSubCategory, selectedCategory]);

    // Actualizar materias disponibles cuando cambia la subcategoría y especialidad
    useEffect(() => {
        if (selectedSubCategory) {
            let filteredSubjects = selectedSubCategory.subjects;

            if (availableSpecialities.length > 0 && selectedSpeciality) {
                filteredSubjects = filteredSubjects.filter(subject => subject.speciality === selectedSpeciality);
            }

            setSelectedSubjects(filteredSubjects.filter(subject => selectedSubjects.some(selected => selected.value === subject.value)));
        } else {
            setSelectedSubjects([]);
        }
    }, [selectedSpeciality, selectedSubCategory]);

    // Manejar la selección de especialidad
    const handleSpecialityChange = (e) => {
        setSelectedSpeciality(e.target.value);
    };

    // Manejar la adición de materias seleccionadas
    const handleAdd = () => {
        if (!selectedCategory || !selectedSubCategory || selectedSubjects.length === 0) {
            Swal.fire('Error', 'Por favor, completa todos los campos.', 'error');
            return;
        }

        // Obtener los IDs de las materias seleccionadas
        const selectedSubjectIds = selectedSubjects.map(subject => subject.value);

        // Incluir speciality si aplica
        const dataToSubmit = {
            selectedSubjectIds,
            speciality: availableSpecialities.length > 0 ? selectedSpeciality : undefined,
        };

        onSubmit(dataToSubmit);
        // Reiniciar estados y cerrar el modal
        setSelectedCategory(null);
        setSelectedSubCategory(null);
        setAvailableSubCategories([]);
        setAvailableSpecialities([]);
        setSelectedSpeciality('');
        setSelectedSubjects([]);
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Seleccionar Materias"
            className="modal bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto my-20"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        >
            <h2 className="text-2xl font-bold mb-4">Seleccionar Materias</h2>
            {isLoading ? (
                <div className="text-center">Cargando categorías...</div>
            ) : (
                <div className="space-y-4">
                    {/* Categoría */}
                    <Select
                        options={categories}
                        onChange={option => setSelectedCategory(option)}
                        placeholder="Seleccione una categoría"
                        isClearable
                        value={selectedCategory}
                        className="react-select-container"
                    />

                    {/* Subcategoría */}
                    {selectedCategory && (
                        <Select
                            options={availableSubCategories}
                            onChange={option => setSelectedSubCategory(option)}
                            placeholder="Seleccione una subcategoría"
                            isClearable
                            value={selectedSubCategory}
                            className="react-select-container"
                        />
                    )}

                    {/* Especialidad */}
                    {availableSpecialities.length > 0 && (
                        <div>
                            <label className="block text-gray-700 mb-1">Especialidad</label>
                            <select
                                value={selectedSpeciality}
                                onChange={handleSpecialityChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                required
                            >
                                <option value="" disabled>Seleccione la especialidad</option>
                                {availableSpecialities.map(spec => (
                                    <option key={spec} value={spec}>{spec}</option>
                                ))}
                            </select>
                        </div>
                    )}

                    {/* Materias */}
                    {selectedSubCategory && (
                        <Select
                            isMulti
                            options={selectedSubCategory.subjects.filter(subject => {
                                if (availableSpecialities.length > 0 && selectedSpeciality) {
                                    return subject.speciality === selectedSpeciality;
                                }
                                return true;
                            })}
                            onChange={setSelectedSubjects}
                            placeholder="Seleccione materia(s)"
                            value={selectedSubjects}
                            className="react-select-container"
                        />
                    )}

                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onRequestClose}
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                        >
                            Cancelar
                        </button>
                        <button
                            type="button"
                            onClick={handleAdd}
                            className="px-4 py-2 text-sm font-medium rounded-md bg-green-600 text-white hover:bg-green-700"
                            disabled={
                                !selectedCategory ||
                                !selectedSubCategory ||
                                selectedSubjects.length === 0 ||
                                (availableSpecialities.length > 0 && !selectedSpeciality)
                            }
                        >
                            Agregar
                        </button>
                    </div>
                </div>
            )}
        </Modal>
    );

}

SubjectsSelectionModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    subjects: PropTypes.array.isRequired,
};

export default SubjectsSelectionModal;
