// src/components/TeacherCard.js
import React from 'react';
import StarDisplay from './rating/StarDisplay';
import { useNavigate } from 'react-router-dom';

const TeacherCard = ({ teacher, handleOpenReviewModal, handleStartChat }) => {

  const navigate = useNavigate();

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg text-gray-800 transform transition-transform hover:scale-105 hover:shadow-xl">
      <div className="flex items-center mb-4">
        {teacher.user.profileImage ? (
          <img src={teacher.user.profileImage} alt="Avatar" className="w-16 h-16 rounded-full flex-shrink-0" />
        ) : (
          <div className="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0"></div>
        )}
        <div className="ml-4">
          <h2
            className="text-2xl font-bold cursor-pointer"
            onClick={() => navigate(`/users/teachers/user/${teacher.user.id}`)} // Usamos user.id
          >
            {teacher.user.name}
          </h2>
        </div>
      </div>
      <p className="mb-2 text-gray-700">{teacher.bio}</p>
      <p className="mb-2 text-gray-700">
        <span className="font-semibold">Tarifa por hora:</span> €{teacher.hourly_rate}
      </p>
      <p className="mb-2 text-gray-700">
        <span className="font-semibold">Contacto:</span> {teacher.user.email}
      </p>
      <p className="mb-2 text-gray-700">
        <span className="font-semibold cursor-pointer" onClick={() => navigate(`/users/teachers/user/${teacher.user.id}`)}>
          Rating:
        </span>{' '}
        <StarDisplay rating={teacher.rating} /> ({teacher.reviews_count})
      </p>
      <p className="mb-2 text-gray-700">
        <span className="font-semibold">Materias:</span> {teacher.subjects.map((subject) => subject.name).join(', ')}
      </p>
      <p className="mb-2 text-gray-700">
        <span className="font-semibold">Ubicaciones:</span> {teacher.availability}
      </p>
      <button
        className="mt-4 w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-700 transition"
        onClick={() => handleOpenReviewModal(teacher)}
      >
        Dejar una Reseña
      </button>

      <button
        className="mt-2 w-full bg-green-500 text-white p-2 rounded-lg hover:bg-green-700 transition"
        onClick={() => handleStartChat(teacher)}
      >
        Enviar Mensaje
      </button>
    </div>
  );
};

export default TeacherCard;
