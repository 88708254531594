// src/pages/TeacherProfilePage.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import useTeacherProfile from '../../hooks/useTeacherProfile';
import StarDisplay from '../../components/rating/StarDisplay';
import ReviewsList from '../../components/review/ReviewsList';

function TeacherProfilePage() {
  const { id } = useParams();
  const { teacher, loading, error } = useTeacherProfile(id);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white">
        <p className="text-lg">Cargando perfil del profesor...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white">
        <p className="text-lg">{error}</p>
      </div>
    );
  }

  if (!teacher) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white">
        <p className="text-lg">No se encontró el perfil del profesor.</p>
      </div>
    );
  }

  // Calcular el rating general con dos decimales
  const teacherRating = Number(teacher.rating);

  const ratingGeneral = teacherRating ? teacherRating.toFixed(2) : 0;

  return (
    <div className="min-h-screen bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white p-10">
      <div className="bg-white p-6 rounded-lg shadow-lg text-gray-800">
        <div className="flex items-center mb-4">
          {teacher.user.profileImage ? (
            <img
              src={teacher.user.profileImage}
              alt="Avatar"
              className="w-16 h-16 rounded-full flex-shrink-0"
            />
          ) : (
            <div className="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0"></div>
          )}
          <div className="ml-4">
            <h2 className="text-2xl font-bold">{teacher.user.name}</h2>
          </div>
        </div>
        <p className="mb-2 text-gray-700">{teacher.bio}</p>
        <p className="mb-2 text-gray-700">
          <span className="font-semibold">Tarifa por hora:</span> €{teacher.hourly_rate}
        </p>
        <p className="mb-2 text-gray-700 flex items-center">
          <span className="font-semibold mr-2">Rating General:</span>
          <StarDisplay rating={teacher.rating} />
          <span className="ml-2">({ratingGeneral})</span>
        </p>
        {teacher.subjects && (
          <p className="mb-2 text-gray-700">
            <span className="font-semibold">Materias:</span>{' '}
            {teacher.subjects.map((subject) => subject.name).join(', ')}
          </p>
        )}
        <p className="mb-2 text-gray-700">
          <span className="font-semibold">Ubicaciones:</span> {teacher.availability}
        </p>

        {/* Botón para dejar una reseña */}
        {/* Aquí podrías agregar un componente o lógica para permitir al usuario dejar una reseña */}

        {/* Mostrar la lista de reseñas */}
        <ReviewsList reviews={teacher.reviews} />
      </div>
    </div>
  );
}

export default TeacherProfilePage;
