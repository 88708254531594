// src/components/ReviewsList.jsx
import React from 'react';
import StarDisplay from '../rating/StarDisplay';

function ReviewsList({ reviews }) {
  return (
    <div className="mt-6">
      <h3 className="text-xl font-bold">Reseñas:</h3>
      {reviews && reviews.length > 0 ? (
        reviews.map((review) => (
          <div key={review.id} className="bg-gray-100 p-4 rounded-lg shadow mt-4 text-gray-800">
            <div className="flex items-center">
              <div className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"></div>
              <div className="ml-4">
                <p className="font-semibold">
                  {review.student && review.student.name
                    ? review.student.name
                    : 'Usuario Desconocido'}
                </p>
              </div>
            </div>
            <div className="mt-2">
              <p>Conocimiento de la materia:</p>
              <StarDisplay rating={review.conocimientoMateria} />
            </div>
            <div className="mt-2">
              <p>Capacidad de explicarse:</p>
              <StarDisplay rating={review.capacidadExplicarse} />
            </div>
            <div className="mt-2">
              <p>Puntualidad:</p>
              <StarDisplay rating={review.puntualidad} />
            </div>
            <p className="mt-2">{review.comment}</p>
          </div>
        ))
      ) : (
        <p>No hay reseñas disponibles.</p>
      )}
    </div>
  );
}

export default ReviewsList;
