// src/components/TeacherProfileView.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        stars.push(
            <FontAwesomeIcon
                key={i}
                icon={faStar}
                className={i <= rating ? 'text-yellow-400' : 'text-gray-300'}
            />
        );
    }
    return stars;
};

const TeacherProfileView = ({ teacher }) => {
    return (
        <div>
            <div className="flex items-center mb-4">
                {teacher.user?.profileImage ? (
                    <img src={teacher.user.profileImage} alt="Avatar" className="w-16 h-16 rounded-full flex-shrink-0" />
                ) : (
                    <div className="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0"></div>
                )}
                <div className="ml-4 flex-grow">
                    <h2 className="text-2xl font-bold">{teacher.user?.name || 'Nombre no disponible'}</h2>
                </div>
            </div>
            <p className="mb-2">{teacher.bio}</p>
            <p className="mb-2">
                <span className="font-semibold">Tarifa por hora:</span> €{teacher.hourly_rate}
            </p>
            <p className="mb-2">
                <span className="font-semibold">Rating:</span> {renderStars(teacher.rating)} ({teacher.reviews_count})
            </p>
            {teacher.subjects && (
                <p className="mb-2">
                    <span className="font-semibold">Materias:</span> {teacher.subjects.map((subject) => subject.name).join(', ')}
                </p>
            )}
            <p className="mb-2">
                <span className="font-semibold">Ubicaciones:</span> {teacher.availability}
            </p>
        </div>
    );
};

export default TeacherProfileView;
