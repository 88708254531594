// src/hooks/useTeacherProfile.js
import { useState, useEffect } from 'react';
import { get } from '../helpers/apiHelper';

function useTeacherProfile(teacherId) {
    const [teacher, setTeacher] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTeacher = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await get(`users/teachers/${teacherId}`);
                setTeacher(response);
            } catch (error) {
                console.error('Failed to fetch teacher:', error);
                setError('Failed to fetch teacher. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchTeacher();
    }, [teacherId]);

    return { teacher, loading, error };
}

export default useTeacherProfile;
