// src/components/Message.js
import React from 'react';

function Message({ message, userId }) {
    const isOwnMessage = message.sender.id === userId;

    return (
        <div className={`mb-4 flex ${isOwnMessage ? 'justify-end' : 'justify-start'}`}>
            <div
                className={`rounded-lg px-4 py-2 max-w-xs ${isOwnMessage ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-800'
                    }`}
            >
                <p>{message.content}</p>
                <p className="text-xs mt-1 text-right">
                    {new Date(message.createdAt).toLocaleTimeString()}
                </p>
            </div>
        </div>
    );
}

export default Message;
