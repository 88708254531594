// src/components/PublicRoute.jsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthStore from '../../store/authStore';

function PublicRoute() {
  const user = useAuthStore(state => state.user);

  if (user) {
    if (user.role === 'teacher') {
      return <Navigate to="/dashboard-teacher" replace />;
    } else if (user.role === 'student') {
      return <Navigate to="/dashboard-student" replace />;
    }
  }

  return <Outlet />;
}

export default PublicRoute;
