// src/pages/TeacherDetails.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import TeacherProfileView from '../../components/profile/TeacherProfileView';
import ReviewsList from '../../components/review/ReviewsList';

const TeacherDetails = () => {
    const { userId } = useParams(); // Obtenemos el userId de la URL
    const [teacher, setTeacher] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTeacher = async () => {
            try {
                const data = await get(`users/teachers/user/${userId}`);
                setTeacher(data); // Asignamos directamente los datos recibidos
            } catch (err) {
                setError('Error al cargar el perfil del profesor.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchTeacher();
    }, [userId]);

    if (loading) return <p>Cargando...</p>;
    if (error) return <p className="text-red-500">{error}</p>;
    if (!teacher) return <p className="text-red-500">Profesor no encontrado.</p>;

    return (
        <div className="min-h-screen bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white p-10">
            <h1 className="text-4xl font-bold mb-6">Detalles del Profesor</h1>
            <div className="bg-white p-6 rounded-lg shadow-lg text-black">
                <TeacherProfileView teacher={teacher} />
                {/* Lista de Reseñas */}
                <ReviewsList reviews={teacher.reviews} />
            </div>
        </div>
    );
};

export default TeacherDetails;
