// src/store/notificationStore.js
import create from 'zustand';

const useNotificationStore = create((set) => ({
    totalUnreadMessages: 0,
    setTotalUnreadMessages: (count) => set({ totalUnreadMessages: count }),
    incrementUnreadMessages: () =>
        set((state) => ({ totalUnreadMessages: state.totalUnreadMessages + 1 })),
    resetUnreadMessages: () => set({ totalUnreadMessages: 0 }),
}));

export default useNotificationStore;
