import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

function StarDisplay({ rating }) {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <FontAwesomeIcon
        key={i}
        icon={faStar}
        className={`inline-block ${i <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
      />
    );
  }
  return <span className="inline-flex items-center">{stars}</span>;
}

export default StarDisplay;
