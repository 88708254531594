// src/components/MessageInput.js
import React, { useState } from 'react';

function MessageInput({ onSendMessage }) {
    const [content, setContent] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (content.trim() !== '') {
            onSendMessage(content);
            setContent('');
        }
    };

    return (
        <form className="border-t border-gray-300 p-4 flex" onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="Escribe un mensaje..."
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="flex-1 px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-green-500"
            />
            <button
                type="submit"
                className="ml-4 px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
                Enviar
            </button>
        </form>
    );
}

export default MessageInput;
