// src/pages/auth/RegisterTeacher.jsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import SubjectSelector from '../../components/auth/SubjectSelector';
import AvatarModal from '../../components/profile/AvatarModal';
import { post } from '../../helpers/apiHelper';
import Modal from 'react-modal';
import ProvinceSelector from '../../components/auth/ProvinceSelector';

Modal.setAppElement('#root');

function RegisterTeacher() {
  // Estados para el formulario de registro
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [bio, setBio] = useState('');
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [hourlyRate, setHourlyRate] = useState('');
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const navigate = useNavigate();

  // Definición de avatares disponibles
  const avatars = Array.from({ length: 14 }, (_, i) => ({
    value: `avatar${i + 1}.webp`,
    label: `Avatar ${i + 1}`,
    url: `/avatars/avatar${i + 1}.webp`,
  }));

  // Manejar el registro de profesor
  const handleRegister = async (event) => {
    event.preventDefault();

    // Validaciones básicas
    if (password !== confirmPassword) {
      Swal.fire('Error', 'Las contraseñas no coinciden!', 'error');
      return;
    }

    const validSelectedSubjects = selectedSubjects;

    if (validSelectedSubjects.length === 0) {
      Swal.fire('Error', 'Por favor, selecciona al menos una materia.', 'error');
      return;
    }

    if (!hourlyRate || isNaN(hourlyRate) || Number(hourlyRate) <= 0) {
      Swal.fire('Error', 'Por favor, ingresa una tarifa por hora válida.', 'error');
      return;
    }

    // Validación del checkbox de términos y condiciones
    if (!acceptedTerms) {
      Swal.fire('Error', 'Debes aceptar los términos y condiciones para registrarte.', 'error');
      return;
    }

    const registerData = {
      name,
      email,
      password,
      country: 'España',
      role: 'teacher',
      bio,
      subjects: validSelectedSubjects.map(s => {
        const id = Number(s.value);
        if (isNaN(id)) {
          throw new Error(`Invalid subject ID: ${s.value}`);
        }
        return id;
      }),
      hourly_rate: Number(hourlyRate),
      availability: selectedCities.map(city => city.value).join(', '),
      profileImage: selectedAvatar,
    };
    console.log(registerData);

    try {
      await post('auth/register', registerData);
      Swal.fire('Éxito', 'Registro exitoso! Por favor, verifica tu correo electrónico.', 'success');
      navigate('/');
    } catch (error) {
      console.log(error);
      console.error('Registration failed:', error);
      Swal.fire('Error', `El registro falló. ${error.response?.data?.message || error.message}`, 'error');
    }
  };

  // Abrir el modal de avatar
  const openAvatarModal = () => {
    setIsAvatarModalOpen(true);
  };

  // Cerrar el modal de avatar
  const closeAvatarModal = () => {
    setIsAvatarModalOpen(false);
  };

  // Seleccionar un avatar
  const selectAvatar = (avatar) => {
    setSelectedAvatar(avatar.url);
    closeAvatarModal();
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-600 via-green-400 to-green-600 text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <h2 className="mt-6 text-center text-3xl font-extrabold">Crear una cuenta</h2>
        <form className="mt-8 space-y-6" onSubmit={handleRegister}>
          <div className="rounded-md shadow-sm -space-y-px">
            {/* Nombre */}
            <div>
              <label htmlFor="name" className="sr-only">Nombre</label>
              <input
                id="name"
                name="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              />
            </div>
            {/* Correo Electrónico */}
            <div>
              <label htmlFor="email-address" className="sr-only">Correo electrónico</label>
              <input
                id="email-address"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Correo electrónico"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              />
            </div>
            {/* Contraseña */}
            <div>
              <label htmlFor="password" className="sr-only">Contraseña</label>
              <input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Contraseña"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              />
            </div>
            {/* Confirmar Contraseña */}
            <div>
              <label htmlFor="confirm-password" className="sr-only">Confirmar Contraseña</label>
              <input
                id="confirm-password"
                name="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirmar Contraseña"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>

          {/* Biografía */}
          <div>
            <label htmlFor="bio" className="sr-only">Biografía</label>
            <textarea
              id="bio"
              name="bio"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              placeholder="Biografía"
              required
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
            />
          </div>

          {/* Provincia */}
          <div>
            <ProvinceSelector
              selectedProvince={selectedProvince}
              setSelectedProvince={setSelectedProvince}
              selectedCities={selectedCities}
              setSelectedCities={setSelectedCities}
              availableCities={availableCities}
              setAvailableCities={setAvailableCities}
            />
          </div>

          {/* Materias */}
          <div>
            <SubjectSelector
              selectedSubjects={selectedSubjects}
              setSelectedSubjects={setSelectedSubjects}
            />
          </div>

          {/* Tarifa por Hora */}
          <div>
            <label htmlFor="hourlyRate" className="sr-only">Tarifa por Hora</label>
            <input
              id="hourlyRate"
              name="hourlyRate"
              type="number"
              value={hourlyRate}
              onChange={(e) => setHourlyRate(e.target.value)}
              placeholder="Tarifa por hora (€)"
              required
              min="1"
              step="0.01"
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
            />
          </div>

          {/* Avatar */}
          {selectedAvatar && (
            <div className="flex justify-center mb-4">
              <img src={selectedAvatar} alt="Selected Avatar" className="w-24 h-24 rounded-full" />
            </div>
          )}

          <button
            type="button"
            onClick={openAvatarModal}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Escoger Avatar
          </button>

          {/* Checkbox de Términos y Condiciones */}
          <div className="flex items-center">
            <input
              id="terms"
              name="terms"
              type="checkbox"
              checked={acceptedTerms}
              onChange={(e) => setAcceptedTerms(e.target.checked)}
              className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
              required
            />
            <label htmlFor="terms" className="ml-2 block text-sm">
              Acepto{' '}
              <Link to="/terminos" className="text-blue-500 hover:text-blue-700 underline">
                los términos y condiciones
              </Link>
              .
            </label>
          </div>

          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Registrarse
          </button>
        </form>

        {/* Modal de Avatar */}
        <AvatarModal
          isOpen={isAvatarModalOpen}
          onRequestClose={closeAvatarModal}
          avatars={avatars}
          onSelectAvatar={selectAvatar}
        />
      </div>
    </div>
  );
}

export default RegisterTeacher;
