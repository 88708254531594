// src/components/ReviewModal.jsx
import React, { useState } from 'react';
import { post } from '../../helpers/apiHelper';
import StarRating from '../rating/StarRating';

function ReviewModal({ teacherId, isOpen, onClose, onReviewSubmitted }) {
  const [conocimientoMateria, setConocimientoMateria] = useState(0);
  const [capacidadExplicarse, setCapacidadExplicarse] = useState(0);
  const [puntualidad, setPuntualidad] = useState(0);
  const [comment, setComment] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await post('users/teachers/review', {
        teacherId,
        conocimientoMateria,
        capacidadExplicarse,
        puntualidad,
        comment,
      });
      onReviewSubmitted();
      onClose();
    } catch (error) {
      console.error('Failed to submit review:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center
      justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-black">Dejar una Reseña</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="text-black font-semibold">
              Conocimiento de la materia:
            </label>
            <StarRating
              rating={conocimientoMateria}
              onRatingChange={setConocimientoMateria}
            />
          </div>
          <div className="mb-4">
            <label className="text-black font-semibold">
              Capacidad de explicarse:
            </label>
            <StarRating
              rating={capacidadExplicarse}
              onRatingChange={setCapacidadExplicarse}
            />
          </div>
          <div className="mb-4">
            <label className="text-black font-semibold">Puntualidad:</label>
            <StarRating
              rating={puntualidad}
              onRatingChange={setPuntualidad}
            />
          </div>
          <div className="mb-4">
            <label className="text-black font-semibold">Comentario:</label>
            <textarea
              className="w-full p-2 border border-gray-300 rounded text-black"
              rows="4"
              required
              placeholder="Escribe tu comentario"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="bg-gray-300 p-2 rounded mr-2"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button type="submit" className="bg-blue-500 text-white p-2 rounded">
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReviewModal;
