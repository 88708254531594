// src/components/auth/ProvinceSelector.jsx
import React from 'react';
import Select from 'react-select';
import { provinciasESP } from '../../data/provinciasESP';
import { municipiosESP } from '../../data/municipiosESP';
import PropTypes from 'prop-types';
import '../../styles/react-select-overrides.css';

function ProvinceSelector({
    selectedProvince,
    setSelectedProvince,
    selectedCities,
    setSelectedCities,
}) {
    // Opciones de provincias
    const provinceOptions = provinciasESP.map((provincia) => ({
        value: provincia.id,
        label: provincia.nm,
    }));

    // Filtrar ciudades basadas en la provincia seleccionada
    const getCityOptions = () => {
        if (!selectedProvince) return [];

        // Encuentra el nombre de la provincia seleccionada en minúsculas
        const provinceName = selectedProvince.label.toLowerCase();

        // Filtrar los municipios que pertenecen a la provincia seleccionada
        const filteredMunicipios = municipiosESP.filter(
            (municipio) => municipio.id.toLowerCase() === provinceName
        );

        return filteredMunicipios.map((municipio) => ({
            value: municipio.nm,
            label: municipio.nm,
        }));
    };

    const cityOptions = getCityOptions();

    // Manejar el cambio de provincia
    const handleProvinceChange = (selectedOption) => {
        setSelectedProvince(selectedOption);
        setSelectedCities([]); // Resetear ciudades al cambiar de provincia
    };

    // Manejar el cambio de ciudades
    const handleCityChange = (selectedOptions) => {
        setSelectedCities(selectedOptions || []);
    };

    return (
        <div>
            {/* Selección de Provincia */}
            <div className="mb-4">
                <Select
                    options={provinceOptions}
                    onChange={handleProvinceChange}
                    placeholder="Seleccione una provincia"
                    isClearable
                    value={selectedProvince}
                    className="react-select-container appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                    classNamePrefix="react-select"
                />
            </div>

            {/* Selección de Ciudades */}
            {selectedProvince && (
                <div className="mb-4">
                    <Select
                        isMulti
                        options={cityOptions}
                        onChange={handleCityChange}
                        placeholder="Seleccione ciudades"
                        value={selectedCities}
                        className="react-select-container appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                        classNamePrefix="react-select"
                    />
                </div>
            )}
        </div>
    );
}

ProvinceSelector.propTypes = {
    selectedProvince: PropTypes.object,
    setSelectedProvince: PropTypes.func.isRequired,
    selectedCities: PropTypes.array.isRequired,
    setSelectedCities: PropTypes.func.isRequired,
};

export default ProvinceSelector;
