// src/components/auth/SubjectSelector.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { get } from '../../helpers/apiHelper'; // Asegúrate de tener una función GET en apiHelper

function SubjectSelector({ selectedSubjects, setSelectedSubjects }) {
    const [subjects, setSubjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [specialities, setSpecialities] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedSpeciality, setSelectedSpeciality] = useState(null);
    const [availableSubjects, setAvailableSubjects] = useState([]);

    // Fetch all subjects on component mount
    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const data = await get('subjects'); // Endpoint: GET /subjects
                setSubjects(data);

                // Extraer categorías únicas
                const uniqueCategories = [...new Set(data.map(subject => subject.category))];
                setCategories(uniqueCategories.map(cat => ({ value: cat, label: cat })));
            } catch (error) {
                console.error('Error fetching subjects:', error);
                Swal.fire('Error', 'No se pudieron cargar las materias.', 'error');
            }
        };

        fetchSubjects();
    }, []);

    // Actualizar subcategorías cuando cambia la categoría
    useEffect(() => {
        if (selectedCategory) {
            const filteredSubCategories = subjects
                .filter(subject => subject.category === selectedCategory.value)
                .map(subject => subject.subCategory);
            const uniqueSubCategories = [...new Set(filteredSubCategories)];
            setSubCategories(uniqueSubCategories.map(sub => ({ value: sub, label: sub })));
            setSelectedSubCategory(null);
            setSelectedSpeciality(null);
            setSpecialities([]);
            setAvailableSubjects([]);
        } else {
            setSubCategories([]);
            setSelectedSubCategory(null);
            setSelectedSpeciality(null);
            setSpecialities([]);
            setAvailableSubjects([]);
        }
    }, [selectedCategory, subjects]);

    // Actualizar especialidades cuando cambia la subcategoría
    useEffect(() => {
        if (selectedSubCategory) {
            const filteredSpecialities = subjects
                .filter(subject => subject.category === selectedCategory.value && subject.subCategory === selectedSubCategory.value && subject.speciality)
                .map(subject => subject.speciality);
            const uniqueSpecialities = [...new Set(filteredSpecialities)];
            setSpecialities(uniqueSpecialities.map(spec => ({ value: spec, label: spec })));
            setSelectedSpeciality(null);
        } else {
            setSpecialities([]);
            setSelectedSpeciality(null);
        }
    }, [selectedSubCategory, selectedCategory, subjects]);

    // Actualizar materias disponibles cuando cambia la subcategoría y especialidad
    useEffect(() => {
        if (selectedSubCategory) {
            let filteredSubjects = subjects.filter(subject => subject.category === selectedCategory.value && subject.subCategory === selectedSubCategory.value);

            if (selectedSpeciality) {
                filteredSubjects = filteredSubjects.filter(subject => subject.speciality === selectedSpeciality.value);
            }

            setAvailableSubjects(filteredSubjects.map(subject => ({ value: subject.id, label: subject.name })));
        } else {
            setAvailableSubjects([]);
        }
    }, [selectedSubCategory, selectedSpeciality, selectedCategory, subjects]);

    // Añadir especialidades de forma dinámica si es necesario
    const specialityOptions = selectedSubCategory ? specialities : [];

    return (
        <div>
            {/* Categoría */}
            <div className="mb-2">
                <label className="block text-gray-700 mb-1">Categoría</label>
                <Select
                    options={categories}
                    value={selectedCategory}
                    onChange={setSelectedCategory}
                    placeholder="Seleccione una categoría"
                    isClearable
                />
            </div>

            {/* Subcategoría */}
            {subCategories.length > 0 && (
                <div className="mb-2">
                    <label className="block text-gray-700 mb-1">Subcategoría</label>
                    <Select
                        options={subCategories}
                        value={selectedSubCategory}
                        onChange={setSelectedSubCategory}
                        placeholder="Seleccione una subcategoría"
                        isClearable
                    />
                </div>
            )}

            {/* Especialidad */}
            {specialities.length > 0 && (
                <div className="mb-2">
                    <label className="block text-gray-700 mb-1">Especialidad</label>
                    <Select
                        options={specialities}
                        value={selectedSpeciality}
                        onChange={setSelectedSpeciality}
                        placeholder="Seleccione una especialidad"
                        isClearable
                    />
                </div>
            )}

            {/* Materias */}
            {availableSubjects.length > 0 && (
                <div className="mb-2">
                    <label className="block text-gray-700 mb-1">Materias</label>
                    <Select
                        isMulti
                        options={availableSubjects}
                        value={selectedSubjects}
                        onChange={setSelectedSubjects}
                        placeholder="Seleccione materia(s)"
                    />
                </div>
            )}
        </div>
    );
}

SubjectSelector.propTypes = {
    selectedSubjects: PropTypes.array.isRequired,
    setSelectedSubjects: PropTypes.func.isRequired,
};

export default SubjectSelector;
