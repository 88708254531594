// src/components/chat/ChatWindow.jsx
import React, { useState, useEffect, useRef } from 'react';
import Message from './Message';
import MessageInput from './MessageInput';
import { get, post } from '../../helpers/apiHelper';
import useAuthStore from '../../store/authStore';

function ChatWindow({ chat, socket }) {
    const [messages, setMessages] = useState([]);
    const { user } = useAuthStore();
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (chat) {
            // Obtener los mensajes del chat seleccionado
            get(`messages/chat/${chat.id}`)
                .then((data) => {
                    setMessages(data);
                    scrollToBottom();
                })
                .catch((error) => console.error('Error fetching messages:', error));

            // Marcar mensajes como leídos
            post(`chats/${chat.id}/mark-as-read`, {})
                .then(() => {
                    // Opcionalmente, podrías actualizar el estado de los chats aquí
                })
                .catch((error) => console.error('Error marking messages as read:', error));
        }
    }, [chat]);

    useEffect(() => {
        if (socket) {
            const handleNewMessage = (message) => {
                if (chat && message.chat.id === chat.id) {
                    setMessages((prevMessages) => [...prevMessages, message]);
                    scrollToBottom();
                }
            };

            socket.on('newMessage', handleNewMessage);

            return () => {
                socket.off('newMessage', handleNewMessage);
            };
        }
    }, [socket, chat]);

    const handleSendMessage = (content) => {
        if (content && chat) {
            const receiverId =
                chat.user1.id === user.userId ? chat.user2.id : chat.user1.id;
            const messageData = {
                senderId: user.userId,
                receiverId,
                content,
            };
            socket.emit('sendMessage', messageData);
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    if (!chat) {
        return (
            <div className="w-3/4 flex items-center justify-center">
                <p className="text-gray-500">Selecciona un chat para comenzar</p>
            </div>
        );
    }

    return (
        <div className="w-3/4 flex flex-col">
            <div className="border-b border-gray-300 p-4">
                <h2 className="text-xl font-bold">{chat.otherUserName || 'Desconocido'}</h2>
            </div>
            <div className="flex-1 p-4 overflow-y-auto">
                {messages.map((message) => (
                    <Message key={message.id} message={message} userId={user.userId} />
                ))}
                <div ref={messagesEndRef} />
            </div>
            <MessageInput onSendMessage={handleSendMessage} />
        </div>
    );
}

export default ChatWindow;
